import Image from "next/image";
import Link from "next/link";
import React from "react";
import { BsArrowRight } from "@react-icons/all-files/bs/BsArrowRight";

import Remote from "../components/LoaderRemote";
import { useTranslation } from "../context/TranslationContext";

const ProductCategoryItem = ({ slug, title, image, name, total }) => {
  const { ProductCategoryItemSubText } = useTranslation();
  return (
    <Link href={slug}>
      <a
        title={title}
        className="bg-white p-3 rounded-lg flex mb-[0.875rem]  border-transparent border  duration-200 group  lg:hover:border-product-type-color lg:hover:border"
      >
        <div className="flex items-center w-full">
          <div className="flex flex-shrink-0 mr-6 relative w-24 h-20 rounded overflow-hidden">
            {image && (
              <Image
                src={image}
                alt={title}
                loader={Remote}
                layout={"fill"}
                objectFit={"cover"}
                sizes="
              150px"
              ></Image>
            )}
          </div>
          <div className="flex items-center w-full">
            <div className="flex flex-col">
              <span className="font-semibold text-[1.063rem] pb-1 lg:group-hover:text-product-type-color duration-200">
                {name}
              </span>
              {/* <span className="text-gray-600 text-13 font-semibold">
                {ProductCategoryItemSubText.replace("{count}", total)}
              </span> */}
            </div>
            <div className="ml-auto duration-200 opacity-0 invisible lg:group-hover:opacity-100 lg:group-hover:visible">
              <BsArrowRight className="w-7 h-7 text-product-type-color" />
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default ProductCategoryItem;
