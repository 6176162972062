import React, { useEffect, useState } from "react";
import { GetProductCategories } from "../api";
import ProductCategoryItem from "../ProductCategoryItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import LoaderProductCategories from "../Loaders/LoaderProductCategories";
import Container from "../ProductCategories/Container";
const ProductCategories = () => {
  const [data, setData] = useState(false);
  useEffect(() => {
    GetProductCategories().then((r) => {
      setData(r.data.data);
    });
  }, []);

  return (
    <Container>
      {!data && <LoaderProductCategories />}
      {data && (
        <Swiper
          spaceBetween={20}
          slidesPerView={1}
          modules={[Pagination]}
          pagination={{
            clickable: true,
          }}
          className="swiperPadding"
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {data.map((item, key) => {
            return (
              <SwiperSlide key={key}>
                {item.map((item2, key2) => (
                  <ProductCategoryItem
                    key={key2}
                    slug={item2.url}
                    title={item2.title}
                    name={item2.baslik}
                    image={item2.resim}
                    total={item2.total}
                  ></ProductCategoryItem>
                ))}
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </Container>
  );
};

export default ProductCategories;
